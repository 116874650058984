import React from 'react'
import { RecognizeLabel, RecognizeWrapper } from './atoms'
import ISO27001 from '../../../../images/about/iso27001.png'
import HIPAA from '../../../../images/about/hipaa.png'
import TPN from '../../../../images/about/tpn.png'
import IABM from '../../../../images/about/iabm.png'
import DPP from '../../../../images/about/dpp.png'
import DPPproduction from '../../../../images/about/dpp-production.png'
import DPPbroadcast from '../../../../images/about/dpp-broadcast.png'
import Countries from '../../../../images/about/countries.svg'
import Users from '../../../../images/about/users.svg'
import Sessions from '../../../../images/about/sessions.svg'

const Recognizes = [
  ISO27001,
  HIPAA,
  TPN,
  IABM,
  DPP,
  DPPproduction,
  DPPbroadcast,
]

const labels = [
  {
    icon: Countries,
    data: '120+',
    label: 'Countries',
  },
  {
    icon: Users,
    data: '10M+',
    label: 'Users',
  },
  {
    icon: Sessions,
    data: '300M+',
    label: 'Sessions',
  },
]

export default function Recognize() {
  return (
    <RecognizeWrapper>
      <h2>Recognized by Industry and Users</h2>
      <p>
        Through recognition and certification from multiple industry safety
        associations, Avica has established itself as a trusted provider. We are
        relentlessly confident to offer users the highest level of security and
        exceptional quality services.
      </p>
      <div className="img-container">
        {Recognizes.map((v, i) => (
          <img key={i} src={v} alt="" loading="lazy" />
        ))}
      </div>
      <div className="map">
        {labels.map((l, i) => (
          <RecognizeLabel
            key={i}
            className={`label-item label-${l.label.toLowerCase()}`}
          >
            <div className="label-container">
              <img src={l.icon} alt="" />
              <div className="right">
                <div className="data">{l.data}</div>
                <div className="label">{l.label}</div>
              </div>
            </div>
          </RecognizeLabel>
        ))}
      </div>
    </RecognizeWrapper>
  )
}
