import styled from 'styled-components'
import { Warpper } from '@raylink-overseas/common/components'
import { Media, color } from '@raylink-overseas/common/theme'
import WhoWeAreBg from '../../../../images/about/who-we-are-bg.png'
import WhoWeAreMobileBg from '../../../../images/about/who-we-are-bg-mobile.png'

export const WhoWeAreWrapper = styled(Warpper)`
  display: flex;
  justify-content: flex-end;
  padding: 62px 72px 65px;
  border-radius: 24px;
  background: url(${WhoWeAreBg}) center / cover no-repeat;
  ${Media.phone`
    margin: 0 0.32rem;
    padding: 4.12rem 0.64rem 0.8rem;
    width: calc(100% - 0.64rem);
    border-radius: 0.32rem;
    background: url(${WhoWeAreMobileBg}) top center / cover no-repeat;
  `}
`

export const WhoWeAreContainer = styled.div`
  width: 50%;
  h2 {
    margin-bottom: 24px;
    color: #1f2329;
    font-size: 40px;
    font-weight: 700;
  }
  p {
    color: ${color.textDark};
    font-size: 16px;
    & + p {
      margin-top: 1em;
    }
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    width: 153px;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
  }
  ${Media.phone`
    position: static;
    width: 100%;
    h2 {
      margin-bottom: 0.2rem;
      font-size: 0.48rem;
    }
    p {
      font-size: 0.28rem;
    }
    .btn {
      margin-top: 0.4rem;
      width: 2.32rem;
      height: 0.86rem;
      font-size: 0.3rem;
    }
  `}
`
