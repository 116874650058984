import React from 'react'
import { WhatWeValueCard, WhatWeValueWrapper } from './atoms'
import Priority from '../../../../images/about/priority.png'
import Future from '../../../../images/about/future.png'
import Matters from '../../../../images/about/matters.png'

const values = [
  {
    icon: Priority,
    title: 'Your Security is Our Top Priority',
    desc: 'We follow the latest security standards and regulations. Your personal information and data are 100% protected with our end-to-end AES-256 encryption.',
  },
  {
    icon: Future,
    title: 'Your Expectation Shapes Our Future',
    desc: 'We value the input of users and strive to offer them effective solutions. Our aim is to ensure that every technology and design we develop is user-friendly and caters to their needs.',
  },
  {
    icon: Matters,
    title: 'Your Experience Matters',
    desc: 'We relentlessly pursue technological innovation and constantly update our products to ensure that we provide high-quality services to our users.',
  },
]

export default function WhatWeValue() {
  return (
    <WhatWeValueWrapper>
      <h2>
        <span>What We</span>
        <span className="gradient">Value</span>
      </h2>
      {values.map((v, i) => (
        <WhatWeValueCard key={i}>
          <img src={v.icon} alt="" />
          <h3>{v.title}</h3>
          <p>{v.desc}</p>
        </WhatWeValueCard>
      ))}
    </WhatWeValueWrapper>
  )
}
