import React, { useMemo } from 'react'
import { LeaderShipCard, LeaderShipContainer, LeaderShipWrapper } from './atoms'
import { Warpper } from '@raylink-overseas/common/components'
import { useMedia } from '@raylink-overseas/common/hooks'
import CEO from '../../../../images/about/ceo.png'
import COO from '../../../../images/about/coo.png'
import CTO from '../../../../images/about/cto.png'
import CE from '../../../../images/about/ce.png'
import PD from '../../../../images/about/pd.png'
import MD from '../../../../images/about/md.png'
import TD from '../../../../images/about/td.png'
import PDM from '../../../../images/about/pdm.png'
import AM from '../../../../images/about/am.png'
import ITM from '../../../../images/about/itm.png'
import PJM from '../../../../images/about/pjm.png'

const leaders = [
  {
    name: 'Kenny Zau',
    manager: 'Chief Executive Officer',
    photo: CEO,
    background: '#CDE2FF',
    pcOrder: 8,
    mobileOrder: 10,
  },
  {
    name: 'Gordon Shaw',
    manager: 'Chief Operating Officer',
    photo: COO,
    background: '#CDE2FF',
    pcOrder: 9,
    mobileOrder: 8,
  },
  {
    name: 'Jack Chou',
    manager: 'Chief Technology Officer',
    photo: CTO,
    background: '#CDE2FF',
    pcOrder: 10,
    mobileOrder: 9,
  },
  {
    name: 'Amelia Wilson',
    manager: 'VP, Customer Experience',
    photo: CE,
    background: '#C6CEFF',
    pcOrder: 4,
    mobileOrder: 6,
  },
  {
    name: 'Benjamin Thompson',
    manager: 'Product Director',
    photo: PD,
    background: '#C6CEFF',
    pcOrder: 5,
    mobileOrder: 7,
  },
  {
    name: 'Rachel Lee',
    manager: 'Marketing Director',
    photo: MD,
    background: '#C6CEFF',
    pcOrder: 6,
    mobileOrder: 4,
  },
  {
    name: 'Rajesh Patel',
    manager: 'Technical Director',
    photo: TD,
    background: '#C6CEFF',
    pcOrder: 7,
    mobileOrder: 5,
  },
  {
    name: 'Krystal Chou',
    manager: 'Product Manager',
    photo: PDM,
    background: '#C6CEFF',
    pcOrder: 0,
    mobileOrder: 2,
  },
  {
    name: 'Angela Chan',
    manager: 'Account Manager',
    photo: AM,
    background: '#C6CEFF',
    pcOrder: 1,
    mobileOrder: 3,
  },
  {
    name: 'Vikram Singh',
    manager: 'IT Manager',
    photo: ITM,
    background: '#C6CEFF',
    pcOrder: 2,
    mobileOrder: 0,
  },
  {
    name: 'Joseph Tso',
    manager: 'Project Manager',
    photo: PJM,
    background: '#C6CEFF',
    pcOrder: 3,
    mobileOrder: 1,
  },
]

export default function LeaderShip() {
  const isMedia = useMedia()
  const leadersOrdered = useMemo(
    () =>
      leaders.sort((lp, ln) =>
        isMedia ? lp.mobileOrder - ln.mobileOrder : lp.pcOrder - ln.pcOrder
      ),
    [isMedia]
  )
  return (
    <LeaderShipWrapper>
      <Warpper>
        <h2>Avica Leadership</h2>
        <LeaderShipContainer>
          {leadersOrdered.map((l, i) => (
            <LeaderShipCard
              key={i}
              className="leadership-card"
              backgroundGradientColor={l.background}
            >
              <div className="leadership-container">
                <img src={l.photo} alt="" loading="lazy" />
                <div className="name">{l.name}</div>
              </div>
              <div className="manager">{l.manager}</div>
            </LeaderShipCard>
          ))}
        </LeaderShipContainer>
      </Warpper>
    </LeaderShipWrapper>
  )
}
