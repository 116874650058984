import React from 'react'
import styled from 'styled-components'
import { Layout } from '@raylink-overseas/common/components'
import Banner from './components/banner'
import WhoWeAre from './components/who-we-are'
import WhatWeValue from './components/what-we-value'
import Recognize from './components/recognize'
import LeaderShip from './components/leader-ship'
import JoinOurCommunity from './components/join-our-community'

const LayoutCenter = styled(Layout)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AboutPage = () => {
  return (
    <LayoutCenter pageType="about">
      <Banner />
      <WhoWeAre />
      <WhatWeValue />
      <Recognize />
      <LeaderShip />
      <JoinOurCommunity />
    </LayoutCenter>
  )
}

export default AboutPage
