import styled from 'styled-components'
import { Warpper } from '@raylink-overseas/common/components'
import { Media, color } from '@raylink-overseas/common/theme'

export const WhatWeValueWrapper = styled(Warpper)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 32px;
  padding: 112px 0;
  h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 584px;
    color: #1f2329;
    font-size: 40px;
    font-weight: 700;
    span.gradient {
      background: linear-gradient(
        278deg,
        #56bcee -1.87%,
        #445fff 20.97%,
        #3764f6 42.76%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  ${Media.phone`
    padding: 0.76rem 0.32rem 1.08rem;
    gap: unset;
    row-gap: 0.16rem;
    h2 {
      margin-bottom: 0.48rem;
      width: 100%;
      font-size: 0.48rem;
      gap: 0.12rem;
    }
  `}
`

export const WhatWeValueCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px 80px;
  width: 584px;
  border-radius: 24px;
  background: linear-gradient(180deg, #e1eeff 0%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(12px);
  text-align: center;
  img {
    width: 112px;
  }
  h3 {
    margin: 16px 0 8px;
    color: #1f2329;
    font-size: 24px;
    font-weight: 700;
    white-space: nowrap;
  }
  p {
    color: ${color.textDark};
    font-size: 16px;
  }
  ${Media.phone`
    width: 100%;
    padding: 0.64rem 0.48rem;
    border-radius: 0.32rem;
    img {
      width: 1.76rem;
    }
    h3 {
      margin: 0.32rem 0 0.16rem;
      font-size: 0.4rem;
      white-space: normal;
    }
    p {
      font-size: 0.28rem;
    }
  `}
`
