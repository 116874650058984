import React from 'react'
import { WhoWeAreContainer, WhoWeAreWrapper } from './atoms'
import { Button } from '@raylink-overseas/common/components'
import useHref from '@raylink-overseas/common/hooks/use-href'

export default function WhoWeAre() {
  const paramsHref = useHref()
  return (
    <WhoWeAreWrapper>
      <WhoWeAreContainer>
        <h2>Who We Are</h2>
        <p>
          Avica, headquartered in Singapore, is dedicated to
          offering secure, simple, and high-performing remote desktop services.
          Our cutting-edge technology ensures that users can effortlessly
          access, control, and manage their desktop and mobile devices from
          anywhere, at any time.
        </p>
        <p>
          As a rising contender in the industry, our unwavering focus lies in
          catering to the needs and challenges of users in the modern era. With
          an in-depth exploration of remote technology and mastery of
          industry-leading patented data transmission technology, Avica is
          swiftly gaining recognition. We are at the forefront of an unstoppable
          trend, revolutionizing the accessibility of remote desktop services
          for everyone.
        </p>
        <Button className="btn" as="a" href={paramsHref('/contact')}>
          Contact Us
        </Button>
      </WhoWeAreContainer>
    </WhoWeAreWrapper>
  )
}
