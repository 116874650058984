import React from 'react'
import { useMedia } from '@raylink-overseas/common/hooks'
import { JoinOurCommunityContainer, JoinOurCommunityWrapper } from './atoms'
import Facebook from '../../../../images/contact/facebook.svg'
import FacebookMobile from '../../../../images/contact/facebook-mobile.svg'
import Linkedin from '../../../../images/contact/linkedin.svg'
import LinkedinMobile from '../../../../images/contact/linkedin-mobile.svg'
import Youtube from '../../../../images/contact/youtube.svg'
import YoutubeMobile from '../../../../images/contact/youtube-mobile.svg'
import Discord from '../../../../images/contact/discord.png'
import DiscordMobile from '../../../../images/contact/discord-mobile.png'
import Instagram from '../../../../images/contact/instagram.png'
import InstagramMobile from '../../../../images/contact/instagram-mobile.png'

const contacts = [
  {
    img: Facebook,
    mobileImg: FacebookMobile,
    link: 'https://www.facebook.com/AvicaSoftware',
  },
  {
    img: Linkedin,
    mobileImg: LinkedinMobile,
    link: 'https://www.linkedin.com/company/avica-software/',
  },
  {
    img: Youtube,
    mobileImg: YoutubeMobile,
    link: 'https://www.youtube.com/@AvicaRemoteDesktop',
  },
  {
    img: Instagram,
    mobileImg: InstagramMobile,
    link: 'https://www.instagram.com/avicasoftware/',
  },
  {
    img: Discord,
    mobileImg: DiscordMobile,
    link: 'https://discord.gg/QDEfMg93XS',
  },
]

export default function JoinOurCommunity() {
  const isMedia = useMedia()
  return (
    <JoinOurCommunityWrapper>
      <h2>Join Our Community</h2>
      <JoinOurCommunityContainer>
        {contacts.map((c, i) => (
          <a
            key={i}
            className="contact-wrapper"
            href={c.link}
            rel="nofollow noreferrer"
          >
            <img src={isMedia ? c.mobileImg : c.img} alt="" />
          </a>
        ))}
      </JoinOurCommunityContainer>
    </JoinOurCommunityWrapper>
  )
}
