import { Media, color } from '@raylink-overseas/common/theme'
import styled, { keyframes } from 'styled-components'
import BannerBg from '../../../../images/about/banner-bg.png'
import BannerMobileBg from '../../../../images/about/banner-bg-mobile.png'

export const BorderRadius = keyframes`
  0% {
    width: 1280px;
    border-radius: 200px;
  }
  100% {
    width: calc(100% - 48px);
    border-radius: 40px;
  }
`

export const BannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 16px;
  margin: 92px 24px 112px;
  height: 328px;
  border-radius: 40px;
  background: url(${BannerBg}) center / 100% 100% no-repeat;
  text-align: center;
  animation: ${BorderRadius} 0.6s ease-out forwards;
  .about-avica {
    width: fit-content;
    font-size: 20px;
    font-weight: 500;
    background: linear-gradient(290deg, #445fff 53.77%, #55bcee 97.08%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h1 {
    color: #1f2329;
    font-size: 48px;
    font-weight: 800;
    line-height: 1.145;
  }
  .desc {
    max-width: 848px;
    color: ${color.textDark};
    font-size: 18px;
  }
  ${Media.laptop`
    width: calc(100% - 48px);
    animation: none;
  `}
  ${Media.phone`
    margin: 1.34rem 0.32rem 0.76rem;
    padding: 0.64rem 0.76rem;
    width: calc(100% - 0.64rem);
    height: unset;
    border-radius: 0.48rem;
    row-gap: 0.16rem;
    background: url(${BannerMobileBg}) center / cover no-repeat;
    .about-avica {
      font-size: 0.32rem;
    }
    h1 {
      font-size: 0.52rem;
    }
    .desc {
      max-width: unset;
      font-size: 0.28rem;
    }
  `}
`
