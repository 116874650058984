import React from 'react'
import { BannerWrapper } from './atoms'

export default function Banners() {
  return (
    <BannerWrapper>
      <p className="about-avica">About Avica</p>
      <h1>Connect Everything, Work Anywhere</h1>
      <p className="desc">
        Avica holds a strong commitment to delivering a remote desktop
        experience that is secure, user-friendly, and of the utmost quality.
      </p>
    </BannerWrapper>
  )
}
