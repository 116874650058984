import styled from 'styled-components'
import { Warpper } from '@raylink-overseas/common/components'
import { Media, color } from '@raylink-overseas/common/theme'
import Map from '../../../../images/about/map.png'

export const RecognizeWrapper = styled(Warpper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  h2 {
    color: #1f2329;
    font-size: 40px;
    font-weight: 700;
  }
  p {
    margin: 8px 0 0;
    width: 943px;
    color: ${color.textDark};
    font-size: 16px;
  }
  .img-container {
    display: flex;
    margin: 40px 0 56px;
    width: 905px;
    justify-content: space-between;
    img {
      height: 60px;
    }
  }
  .map {
    position: relative;
    width: 912px;
    height: 492px;
    background: url(${Map}) center / cover no-repeat;
    .label-countries {
      position: absolute;
      top: 96px;
      left: -14px;
      background: linear-gradient(to right, #e7edfe 0%, #fff 100%) no-repeat;
    }
    .label-users {
      position: absolute;
      top: 56px;
      left: 326px;
      background: linear-gradient(to left bottom, #e7edfe 0%, #fff 100%)
        no-repeat;
    }
    .label-sessions {
      position: absolute;
      top: 96px;
      right: -6px;
      background: linear-gradient(to left, #e7edfe 0%, #fff 100%) no-repeat;
    }
  }
  ${Media.phone`
    padding-bottom: 1rem;
    h2 {
      padding: 0 0.32rem;
      font-size: 0.48rem;
    }
    p {
      margin: 0.16 0 0.48rem;
      padding: 0 0.32rem;
      width: 100%;
      font-size: 0.28rem;
    }
    .img-container {
      flex-wrap: wrap;
      justify-content: center;
      margin: 0.48rem 0;
      width: 100%;
      gap: 0.2rem;
      img {
        width: 1.6rem;
        height: 0.72rem;
        object-fit: contain;
      }
    }
    .map {
      display: flex;
      justify-content: space-between;
      padding: 1.82rem 0.39rem 0;
      width: 100%;
      height: 4.04rem;
      .label-item {
        position: static;
      }
    }
  `}
`

export const RecognizeLabel = styled.div`
  overflow: hidden;
  padding: 2px;
  width: 252px;
  border-radius: 12px;
  background: linear-gradient(to bottom, #e7edfe 0%, #fff 100%) no-repeat;
  box-shadow: 0px 32px 24px 0px rgba(204, 204, 204, 0.1);
  backdrop-filter: blur(8px);
  .label-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 16px 26px;
    border-radius: 12px;
    background: rgba(249, 250, 255, 0.85);
    .right {
      text-align: left;
      line-height: 1;
      .data {
        margin-bottom: 6px;
        color: ${color.primary};
        font-size: 32px;
        font-weight: 800;
      }
      .label {
        color: #1f2329;
        font-size: 18px;
      }
    }
  }
  ${Media.phone`
    width: 2rem;
    height: fit-content;
    border-radius: 0.16rem;
    background: linear-gradient(to bottom, #e7edfe 0%, #fff 100%) no-repeat !important;
    .label-container {
      height: fit-content;
      padding: 0.2rem 0.26rem 0.36rem 0.26rem;
      flex-direction: column;
      gap: 0.2rem;
      border-radius: 0.16rem;
      img {
        width: 0.96rem;
        height: 0.96rem;
      }
      .right {
        .data {
          margin-bottom: 0.16rem;
          text-align: center;
          font-size: 0.4rem;
          line-height: 0.7;
        }
        .label {
          text-align: center;
          font-size: 0.28rem;
        }
      }
    }
  `}
`
