import styled from 'styled-components'
import { Warpper } from '@raylink-overseas/common/components'
import { Media } from '@raylink-overseas/common/theme'

export const JoinOurCommunityWrapper = styled(Warpper)`
  padding: 112px 0;
  h2 {
    margin-bottom: 56px;
    color: #1f2329;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
  }
  ${Media.phone`
    padding: 0.76rem 0;
    h2 {
      margin-bottom: 0.56rem;
      font-size: 0.48rem;  
    }
  `}
`
export const JoinOurCommunityContainer = styled.div`
  display: flex;
  justify-content: space-between;
  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 216px;
    height: 104px;
    border-radius: 8px;
    border: 1px solid #ebefff;
    background: #fff;
    &:hover {
      box-shadow: 0px 20px 40px 2px rgba(204, 204, 204, 0.2);
    }
  }
  ${Media.phone`
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.26rem;
    a {
      width: 2.12rem;
      height: 1.44rem;
      border-radius: 0.12rem;
      img {
        width: 0.76rem;
      }
    }
  `}
`
