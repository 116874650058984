import { Media } from '@raylink-overseas/common/theme'
import styled from 'styled-components'

export const LeaderShipWrapper = styled.div`
  padding: 112px 0;
  width: 100%;
  background: linear-gradient(
    180deg,
    #f1f3ff 0%,
    rgba(255, 255, 255, 0) 306.18%
  );
  h2 {
    margin-bottom: 56px;
    color: #1f2329;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
  }
  ${Media.phone`
    padding: 0.76rem 0.48rem;
    h2 {
      margin-bottom: 0.48rem;
      font-size: 0.48rem;
    }
  `}
`

export const LeaderShipContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap-reverse;
  gap: 48px;
  ${Media.phone`
    gap: 0.38rem;
  `}
`

export const LeaderShipCard = styled.div<{ backgroundGradientColor: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 264px;
  .leadership-container {
    position: relative;
    width: 100%;
    height: 174px;
    border-radius: 12px 12px 0px 0px;
    background: linear-gradient(
      180deg,
      ${({ backgroundGradientColor }) => backgroundGradientColor} 0%,
      rgba(255, 255, 255, 0) 100%
    );
    img {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }
    .name {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 4px 14px;
      background: linear-gradient(
        290deg,
        rgba(68, 95, 255, 0.8) 53.77%,
        rgba(85, 188, 238, 0.8) 97.08%
      );
      backdrop-filter: blur(4px);
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      line-height: 1;
    }
  }
  .manager {
    padding: 6px 14px;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(3px);
    color: #1f2329;
    font-size: 14px;
  }
  ${Media.phone`
    width: 3.08rem;
    .leadership-container {
      height: 2rem;
      border-radius: 0.16rem 0.16rem 0rem 0rem;
      img {
        height: 1.78rem;
      }
      .name {
        padding: 0.04rem 0.12rem;
        font-size: 0.28rem;
      }
    }
    .manager {
      padding: 0.04rem 0.08rem;
      font-size: 0.24rem;
    }
  `}
`
